import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Dictionary({ match }) {

    useEffect(() => {
        fetchCategories();
    }, [])

    const [letters] = useState('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''))
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchCategories = async () => {
        setLoading(true)
        const endpoint = `${process.env.REACT_APP_API_URL}/categories`
        fetch(endpoint) // Get this from config
            .then(result => result.json())
            .then(result => {
                setCategories(result)
                setLoading(false)
            })
    }

    return (
        <Container fluid="md" className="mt-5">
            <Row>
                <Card>
                    <Card.Header as='h1'>
                        Browse By Letter
                    </Card.Header>
                    <Card.Body>
                        {letters.map((letter, index) => {
                            return (
                                <Link key={index} to={'/browse/' + letter}>
                                    <Button key={index} size="lg" variant="light">{letter}</Button> {' '}
                                </Link>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Row>
            <Row fluid="md" className="mt-5">
                <Card>
                    <Card.Header as='h1'>
                        Browse By Category
                    </Card.Header>
                    <Card.Body>
                        {categories.map((category, index) => {
                            return (
                                <Link to={'/categories/' + category.id}>
                                    <Button key={category.id} size="lg" variant="light">{category.category}</Button> {' '}
                                </Link>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );

}

export default Dictionary