import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

function MyNavBar() {
  return (
    <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img className="mg-fluid" src="/logo.png" alt="SpeakJargon.dev" />
        </Navbar.Brand>
        <Nav className="navbar nav-collapse mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/browse">Browse</Nav.Link>
          <Nav.Link href="/suggestions/add">Suggestions</Nav.Link>
        </Nav>
        <Nav className="navbar s-auto">
          <a href="https://www.buymeacoffee.com/speakjargon" target="_blank" rel="noreferrer">
            <img className="d-none d-sm-block"
              style={{ height: '2.5em' }}
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee" />
          </a>
        </Nav>
    </Navbar>
  );
}

export default MyNavBar;
