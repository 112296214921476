import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from 'react';
import './AppBootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MyNavBar from './MyNavbar';
import Main from './Main';
import Category from './Category';
import Dictionary from './Dictionary';
import TermsIndex from './TermsIndex';
import AddTerm from './AddTerm';
import Term from './Term';
import Footer from './components/Footer';


function App() {
  return (
    <Router>
      <div>
        <MyNavBar />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/categories/:id" component={Category} />
          <Route path="/terms/:id" component={Term} />
          <Route exact path="/suggestions/add" component={AddTerm} />
          <Route exact path="/browse" component={Dictionary} />
          <Route exact path="/browse/:initial" component={TermsIndex} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
