import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { BsSearch } from "react-icons/bs";
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import PopularCategories from './PopularCategories';
import PopularTerms from './PopularTerms';

function Main() {

    const [terms, setTerms] = useState([])
    const [term, setTerm] = useState('')
    const [link, setLink] = useState(
        <Button variant="primary" size="md" block disabled>Search</Button>
    )
    // const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchTerms()
    }, []);

    const fetchTerms = async (term) => {
        // setLoading(true)
        if (term !== undefined && term !== '') {
            fetch(`${process.env.REACT_APP_API_URL}/terms/autocomplete?search=${term}`)
                .then(result => result.json())
                .then(result => {
                    setTerms(result)
                    // setLoading(false)
                })
        }
    }

    return (
        <Container fluid="md" className="mt-4">
            <Row>
                <Col>
                    <PopularCategories />
                </Col>
                <Col>
                    <PopularTerms />
                </Col>
            </Row>
            <Row style={{ marginLeft: 1, marginRight: 1 }} className="mt-4">
                <Col className="col-sm-12">
                    <Card>
                        <Card.Header as='h4'>
                            <BsSearch /> Search
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>
                                        Please use the search box below to search for existing terms in our system.<br />
                                        If the requested term does not appear in the list then consider adding a suggestion here. Our team will review your request and add the term shortly.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm-10">
                                    <Typeahead
                                        onInputChange={(text, event) => {
                                            fetchTerms(text);
                                        }}
                                        onChange={(selected) => {

                                            if (selected.length === 1 &&
                                                selected[0].hasOwnProperty('id')) {
                                                setTerm(selected[0])
                                                setLink(
                                                    <Link to={'/terms/' + selected[0].id}>
                                                        <Button variant="primary" size="md" block>
                                                            Search
                                                        </Button>
                                                    </Link>
                                                )
                                            } else {
                                                setLink(
                                                    <Button variant="primary" size="md" block disabled>Search</Button>
                                                )
                                            }

                                        }}
                                        options={[...terms]}
                                        labelKey={option => `${option.title}`}
                                    />
                                </Col>
                                <Col className="col-sm-2">
                                    {link}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginLeft: 1, marginRight: 1 }} className="mt-4">
                <Col>
                    <Card>
                        <Card.Header as='h4'>
                            <IoIosInformationCircleOutline /> &nbsp;&nbsp; A few words...
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>
                                        <a href="/">speakjargon.dev</a> is a syllabus of acronyms, terms and expressions related to Software Engineering. On this website you can find several technical terms and expressions sourced from books, tech talks and various articles.
                                        Our Syllabus is getting updated bimonthly - goes through a sign off process and gets published straight after.
                                        <br /><br />
                                        Shall you wish to add an entry that cannot be found on our website, please do create a proposal through our <a href="/suggestions/add">suggestion</a> form.
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Main