import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BsArrowReturnRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function TermsIndex({ match }) {

    console.log(match);

    useEffect(() => {
        fetchAllTermsForInitial();
    }, [])

    const [terms, setTerms] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchAllTermsForInitial = async () => {
        setLoading(true)
        const endpoint = `${process.env.REACT_APP_API_URL}/terms/browse/` + match.params.initial
        fetch(endpoint) // Get this from config
            .then(result => result.json())
            .then(result => {
                setTerms(result)
                setLoading(false)
            })
    }

    if (loading) {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        );
    } else if (terms === undefined || terms.length === 0) {
        return (
            <Container fluid="md" className="mt-3">
                No Terms Available.
            </Container>
        );
    } else {
        return (
            <Container fluid="md" className="mt-3">
                <h1 style={{ fontWeight: 'bold' }}>INDEX: 
                    <Link style={{ textTransform: 'capitalize', fontWeight: 'normal' }}
                        to={"/browse/" + match.params.initial}>{match.params.initial}</Link>
                </h1>
                {terms.map(item => (
                    <div className="mt-3" key={item.id + "-div"}>
                        <hr />
                        <h6 key={item.id + "-h6"}>
                            <BsArrowReturnRight />
                            <Link style={{ paddingLeft: 7 }} to={"/terms/" + item.id}>{item.title}</Link>
                        </h6>
                        <p key={item.id + "-p"}>{item.summary.length > 40
                            ? item.summary.substring(0, 100) + "..."
                            : item.summary}</p>
                    </div>
                ))}
            </Container>
        );
    }

}

export default TermsIndex