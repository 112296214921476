import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import gfm from 'remark-gfm'
import Popularity from './components/Popularity'
import './Term.css'
import { generateDisplayDateFromEpoch } from './utils/date'
import { BiCopy } from "react-icons/bi";

function Term({ match }) {
    const [termId] = useState(match.params.id)
    const [loading, setLoading] = useState(false)
    const [term, setTerm] = useState({
        id: -1,
        title: '',
        description: '',
        rating: 0,
        views: 0
    })
    const [adLoading, setAdLoading] = useState(false)
    const [ads, setAds] = useState([])

    useEffect(() => {
        fetchItem(0);
        // eslint-disable-next-line
        fetchAds(0);
    }, []);

    // Term Fetching
    const fetchItem = async () => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/terms/${termId}`)
            .then(result => result.json())
            .then(result => {
                console.log(result)
                setTerm({
                    ...term,
                    id: result.id,
                    title: result.title,
                    description: result.description,
                    rating: result.rating,
                    views: result.views,
                    lastUpdated: result.lastUpdated
                })
                setInitialRating(result.rating);
                setLoading(false)
            })
    }

    // Ad Fetching
    const fetchAds = async () => {
        setAdLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/ads?termId=${termId}`)
            .then(result => result.json())
            .then(result => {
                console.log(result)
                setAds(result)
                setAdLoading(false)
            })
    }

    // Rating Control
    const [initialRating, setInitialRating] = useState(term.rating)
    // const [rating, setRating] = useState(initialRating);
    const [editing, setEditing] = useState(true);

    const ratingSubmittedAlertTrigger = (attr) => {
        toast(`Your rating has been submitted. 🚀 `, {
            type: 'success'
        });
    }

    const ratingSubmittedAlreadyAlertTrigger = () => {
        toast("You have rated this term already. 🙄 ", {
            type: 'error'
        });
    }

    const rate = (rating, callback) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                rating: rating
            }),
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_API_URL}/terms/${term.id}/ratings`, requestOptions)
            .then(response => {
                console.log(response);
                if (response.status === 409) {
                    ratingSubmittedAlreadyAlertTrigger();
                } else {
                    callback(rating);
                    ratingSubmittedAlertTrigger(term.title);
                }
            }).catch(error => {
                alert(`Failed to rate the term ${term.title} - Please Try again later!`);
                console.log(error);
            })
    }

    const onStarClick = (nextValue, prevValue, name) => {
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        if (editing) {
            rate(nextValue, (value) => {
                setTerm({ ...term, rating: value })
                setInitialRating(value);
                setEditing(false);
            });

        } else {
            ratingSubmittedAlreadyAlertTrigger(term.title)
        }
    }

    const onStarHover = (nextValue, prevValue, name) => {
        console.log('OnStarHover -> name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        // setRating(nextValue)
        setTerm({ ...term, rating: nextValue })
    }

    const onStarHoverOut = (nextValue, prevValue, name) => {
        console.log('OnStarHoverOut -> name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        console.log('OnStarHoverOut -> Initial Rating: %s', initialRating);
        setTerm({ ...term, rating: initialRating })
    }

    const renderBookAds = () => {
        if (adLoading) {
            return (
                <Row>
                    <h1>Loading...</h1>
                </Row>
            );
        } else {
            return (<Row>
                {ads.map(item => (
                    <Col key={item}>
                        <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                            title={item.title}
                            style={{ width: "120px", height: "240px" }}
                            marginWidth="0"
                            marginHeight="0"
                            scrolling="no"
                            frameBorder="0"
                            src={item.link}>

                        </iframe>
                    </Col>
                ))}
            </Row>)
        }

    }

    if (loading) {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        );
    } else {
        return (
            <Container fluid="md" className="mt-3">
                <ToastContainer />
                <Row className="align-items-end">
                    <Col sm={10}>
                        <h1>
                            <Link style={{ textTransform: 'capitalize', fontWeight: 'normal' }} to={"/terms/" + term.id}>{term.title}</Link>
                        </h1>
                    </Col>
                    {/* <Col sm={2} className="text-right">
                     <BiCopy/>
                    </Col> */}
                    <Col sm={2}>
                        <p className="text-right">
                            <small className="text-muted d-none d-lg-block">Last Updated: {generateDisplayDateFromEpoch(term.lastUpdated)}</small>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col>
                        <ReactMarkdown style={{ fontFamily: 'Metro Nova' }} children={term.description} plugins={[gfm]} />
                        <hr />
                    </Col>
                </Row>

                <Row>
                    <Col sm={10}>
                        <Popularity
                            term={term}
                            isEditable={editing}
                            onStarClick={onStarClick}
                            onStarHover={onStarHover}
                            onStarHoverOut={onStarHoverOut}
                        />
                    </Col>
                    <Col sm={2}>
                        <p className="text-right">
                            <small className="text-muted text-right">Total Views: {term.views}</small>
                        </p>
                    </Col>
                </Row>

                {
                    renderBookAds()
                }
            </Container>
        );
    }
}

export default Term