import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BsArrowReturnRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Category({ match }) {
    const [categoryName, setCategoryName] = useState('')
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [page, setPage] = useState({
        currentPage: 0,
        totalPages: 0
    })
    const paginationItemsCount = 10;

    useEffect(() => {
        fetchName()
        fetchItem(0);
    }, []); // brackets mimic the componendDidMount method, if not here there will be a look fetching data

    const fetchName = async (number) => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/categories/${match.params.id}`)
            .then(result => result.json())
            .then(result => {
                setCategoryName(result.category)
                setLoading(false)

            })
    }

    const fetchItem = async (number) => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/terms?category=${match.params.id}&page=${number}&size=${paginationItemsCount}`)
            .then(result => result.json())
            .then(result => {
                setItems( result.content !== undefined ? result.content : [])
                setPage({
                    ...page,
                    currentPage: result.pageNumber,
                    totalPages: result.totalPages
                })
                setLoading(false)

            })
    }

    if (loading) {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        );
    } else if (items === undefined || items.length === 0){
        return (
            <Container fluid="md" className="mt-3">
                <h1>Category: {categoryName}</h1>
                Currently, there are no terms associated with this category.
            </Container>
        );
    } else {
        return (
            <Container fluid="md" className="mt-3">
                <h1 style={{fontWeight: 'bold'}}>CATEGORY: <Link style={{textTransform: 'capitalize', fontWeight: 'normal'}} to={"/categories/" + match.params.id}>{categoryName}</Link></h1>
                {items.map(item => (
                    <div className="mt-3" key={item.id + "-div"}>
                        <hr/>
                        <h6 key={item.id + "-h6"}>
                            <BsArrowReturnRight/>
                            <Link style={{paddingLeft:7}} to={"/terms/" + item.id}>{item.title}</Link>
                        </h6>
                        <p key={item.id + "-p"}>{item.summary.length > 40
                            ? item.summary.substring(0, 100) + "..."
                            : item.summary}</p>
                    </div>
                ))}
                <nav>
                    <ul className="pagination justify-content-center">
                        {page.currentPage === 0
                            ? <li className="page-item disabled"><a className="page-link" tabIndex="-1">Previous</a></li>
                            : <li className="page-item"><a className="page-link" onClick={() => fetchItem(page.currentPage - 1)} tabIndex="-1">Previous</a></li>
                        }
                        {[...Array(page.totalPages)].map((e, i) => {
                            if (page.currentPage === i) {
                                return <li key={i} className="page-item disabled"><a className="page-link" key={i}>{i}</a></li>
                            } else {
                                return <li key={i} className="page-item"><a className="page-link" onClick={() => fetchItem(i)} key={i}>{i}</a></li>
                            }
                        })}
                        {page.currentPage === page.totalPages - 1
                            ? <li className="page-item disabled"><a className="page-link">Next</a></li>
                            : <li className="page-item"><a className="page-link" onClick={() => fetchItem(page.currentPage + 1)}>Next</a></li>
                        }
                    </ul>
                </nav>
            </Container>
        );
    }

}

export default Category