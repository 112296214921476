import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { BiPurchaseTagAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';

function PopularCategories() {

    useEffect(() => {
        fetchPopularCategories();
    }, [])

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchPopularCategories = async () => {
        setLoading(true)
        const endpoint = `${process.env.REACT_APP_API_URL}/categories/popular`
        fetch(endpoint) // Get this from config
            .then(result => result.json())
            .then(result => {
                setCategories(result)
                setLoading(false)
            })
    }

    if (loading) {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        );
    } else {
        return (
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header as='h4'>
                                <BiPurchaseTagAlt /> Popular Categories
                                {/* <small class="float-sm-right">11</small> */}
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-group list-group-flush">
                                    {categories.map(item => (
                                        <li style={{textTransform: "capitalize"}} className="list-group-item" key={item.category.id}>
                                            <Link className="categoryLink medium" style={{textTransform: 'capitilize'}}
                                                to={'/categories/' + item.category.id}>{item.category.category}
                                                <span style={{ color: "gray" }} className="small"> | Terms: {item.count}</span></Link><br />
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default PopularCategories