import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        // <div className="fixed-bottom">
        <div style={{paddingTop: "2em", position: "absolute", width: "100%"}}>
            <Container>
                <Row>
                    <Col className="text-center">
                        <div>
                            <p>&copy; Copyright 2020 - {new Date().getFullYear()} | All rights reserved | <strong>speakjargon.dev</strong> | Hand-crafted with ❤ &nbsp; in <b>London</b></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;