import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'


function AddTerm() {

    const SITE_KEY = '6Le46X0aAAAAAHMucjianiS2iNI9kF7dhI82zkPT';

    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState(true);
    const [description, setDescription] = useState('');
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    const handleOnClick = e => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                submitData(token);
            });
        });
    }

    const validEmail = email => {
        const re = /^[^\s@]+@[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const submitData = token => {
        setLoading(true);
        var descriptionValidity = true; // hooks don't update immediately, thus the hack
        if (!description || description === ""){
            setDescriptionValid(false);
            descriptionValidity=false;
        } else {
            setDescriptionValid(true);
            descriptionValidity=true;
        }

        var titleValidity = true;
        if (!title || title === ""){
            setTitleValid(false);
            titleValidity=false;
        } else {
            setTitleValid(true);
            titleValidity=true;
        }

        var emailValidity = true;
        if (!email || email === "" || !validEmail(email)){
            setEmailValid(false);
            emailValidity = false;
        } else {
            setEmailValid(true);
            emailValidity = true;
        }

        if (emailValidity && titleValidity && descriptionValidity){
            console.log("got in");
            const endpoint = `${process.env.REACT_APP_API_URL}/terms/suggestions`
            fetch(endpoint, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "title": title,
                    "description": description,
                    "email": email,
                    "gRecaptchaResponse": token
                })
            }).then(res => {
                if (res.status !== 202){
                    var error = "We were unable to process the submission. Please try again later.";
                    console.log(res);
                    res.json().then(json => {
                        error = json.message;
                        throw new Error(error);
                    }).catch(error => {
                        alert(error);
                        setLoading(false);
                        setSubmitted(false);
                    });
                } else {
                    setLoading(false);
                    setSubmitted(true);
                }
            }).catch(error => {
                alert(error);
                setLoading(false);
                setSubmitted(false);
            });
        } else {
            setLoading(false);
        }
    }

    const submissionButtonBasedOnStatus = () => {

        if (loading){
            return <Button onClick={handleOnClick} variant="primary" type="submit" disabled={loading}>Submitting</Button>
        } else {
            if (submitted){
                return <Button onClick={handleOnClick} variant="primary" type="submit" disabled>Submitted</Button>
            } else {
                return <Button onClick={handleOnClick} variant="primary" type="submit">Submit</Button>
            }
        }
    }

    return (
        <Container fluid="md" className="mt-3">
            <Row>
                <Col>
                    <h1>Suggestion Submission</h1>
                    <p>
                        A term/expression might be missing from <a href="/">speakjargon.dev</a>'s rich collection of terms. If you looked into our syllabous and you didn't manage to find what you were after, please be aware that we welcome term/expression suggestions.
                        Our team does review new terms very frequently.
                        Once your suggestion has been assessed, our team of volunteers will do the research required, describe the suggested term in a very simple way and then add the new term in our syllabous.
                        <br></br><br></br>
                        <strong>Please, use the form below to suggest a new term or expression.</strong>
                    </p>
                    <Form>
                        <Form.Group controlId="suggestion.Email">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                size="lg"
                                type="email"
                                value={email}
                                onChange={e => { e.preventDefault(); setEmail(e.target.value) }}
                                placeholder="Enter enter you email here" />
                            {!emailValid &&
                                <Form.Label style={{ color: 'red' }}>Email required or is not formatted properly</Form.Label>
                            }
                        </Form.Group>
                        <Form.Group controlId="suggestion.Title">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                                size="lg"
                                type="text"
                                value={title}
                                onChange={e => { e.preventDefault(); setTitle(e.target.value) }}
                                placeholder="Please enter a suggested term or expression here." />
                            {!titleValid &&
                                <Form.Label style={{ color: 'red' }}>Title is required</Form.Label>
                            }
                        </Form.Group>
                        <Form.Group controlId="suggestion.ControlTextarea1">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                                size="lg"
                                as="textarea" rows={8}
                                value={description}
                                onChange={e => { e.preventDefault(); setDescription(e.target.value) }}
                                placeholder="Enter suggested description here. This won't be the final description, this is for us to understand more details about the suggested term." />
                            {!descriptionValid &&
                                <Form.Label style={{ color: 'red' }}>Description is required</Form.Label>
                            }
                        </Form.Group>
                        {
                           submissionButtonBasedOnStatus()
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default AddTerm