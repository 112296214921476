import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { BiAbacus } from "react-icons/bi";
import { Link } from 'react-router-dom';
import Popularity from './components/Popularity';

function PopularTerms() {

    useEffect(() => {
        fetchPopularTerms();
    }, [])

    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchPopularTerms = async () => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/terms/popular`)
            .then(result => result.json())
            .then(result => {
                setItems(result)
                setLoading(false)
                return true;
            })
    }

    if (loading) {
        return (
            <Container>
                <h1>Loading...</h1>
            </Container>
        );
    } else {
        return (
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header as='h4'>
                                <BiAbacus /> Popular Terms
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-group list-group-flush">
                                    {items.map(item => (
                                        <li style={{textTransform: "capitalize"}} className="list-group-item" key={item.id}>
                                            <Link style={{ float: "left"}} to={'/terms/' + item.id}>{item.title}</Link>
                                            <div className="d-none d-lg-block" style={{float: "right"}}>
                                                <Popularity 
                                                    fontSize={8}
                                                    starColor='#ffdd01'
                                                    term={item}
                                                    isEditable={false}
                                                    onStarClick={e => e.preventDefault()}
                                                    onStarHover={e => e.preventDefault()}
                                                    onStarHoverOut={e => e.preventDefault()}
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default PopularTerms