import React from 'react'
import StarRatingComponent from 'react-star-rating-component';

function Popularity({
    fontSize = 20,
    starColor = "#5f95dd" ,
    term, 
    isEditable, 
    onStarClick,
    onStarHover,
    onStarHoverOut
}) {

    return (
        <div style={{fontSize: fontSize}}>
            <StarRatingComponent 
            name="term-rating" 
            editing={isEditable}
            onStarClick={onStarClick}
            onStarHover={onStarHover}
            onStarHoverOut={onStarHoverOut}
            renderStarIcon={() => <span>★</span>}
            starCount={5}
            starColor={starColor}
            value={term.rating}
            />
      </div>
    );
}

export default Popularity;